import React, { Fragment, useState } from 'react';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "../../contacto.css";
import Image from 'react-bootstrap/Image'
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import emailjs from "emailjs-com";

const Contacto = () => {
    //states de los campos a validar
  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");
  const [telefono, setTelefono] = useState("");
  const [email, setEmail] = useState("");
  const [consulta, setConsulta] = useState("");

  const [error, setError] = useState(false); //false no muestra la alerta...
  const [mailEnviado, setMailEnviado] = useState(false); //para ver si el mail se envio o nel...
  const [mailError, setMailError] = useState(false); //si hubo algun problema con emailjs...

  //definimos funcion a ejecutar cuando se realiza el submit..
  const handleSubmit = (e) => {
    e.preventDefault();
    //validamos los datos...
    let expresion = /\w+@\w+\.[a-z]/;
    if (
      nombre.trim() !== "" &&
      apellido.trim() !== "" &&
      telefono.trim() !== "" &&
      expresion.test(email)
    ) {
      console.log(e.target);
        setError(false);
      emailjs.sendForm(
          "gmail", //your service id
          "template_73wF6YPx", //your template id
          e.target, //de quien
          "user_TpYAljcfICHpDb5PErzaZ" //your ser id
        )
        .then(
          (result) => {
            console.log(result.text);
            console.log(mailEnviado);
            if (result.text === "OK") {
              setMailEnviado(true);
              setNombre("");
              setApellido("");
              setEmail("");
              setTelefono("");
              setConsulta("");

              document.getElementById("form").reset();
              
              alert(
                "Su consulta ha sido enviada. En breve nos comunicaremos con usted."
              );
            }
          },
          (error) => {
            console.log(error.text);
            setMailError(true);
            document.getElementById("form").reset();
          }
        );
    } else {
      setError(true);
      return;
    }
  };
    return (
        <div>
            <div className="container-fluid bg-light text-dark py-3">
                <Row md={1} lg={2} className="d-flex justify-content-center">
                    <Col className=' d-flex justify-content-center py-5 px-4'>
                        <div className=''>
                            
                            <h2 className='color-rojo text-center'>ENVIANOS TU CONSULTA Y NOS COMUNICAREMOS!</h2>
                            <div className='pt-5'>
                                <p>(*)Campos obligatorios</p>
                            </div>
                            <Form className="mb-4" onSubmit={handleSubmit} id="form">
                                <Form.Group controlId="formBasicEmail">
                                    <Row>
                                        <Col>
                                            <Form.Control
                                                name='name'
                                                required
                                                placeholder="(*)Nombre"
                                                type= 'text'
                                                onChange={(e) => {
                                                    setNombre(e.target.value);
                                                }}
                                            />
                                        </Col>
                                        <Col>
                                            <Form.Control
                                                name='apellido'
                                                required
                                                placeholder="(*)Apellido"
                                                type= 'text'
                                                onChange={(e) => {
                                                    setApellido(e.target.value);
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group controlId="formBasicPassword">
                                    <Form.Control required type="text" placeholder="(*)Dirección de la obra" />
                                </Form.Group>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control required type= 'text' placeholder="(*)Localidad" />
                                </Form.Group>
                                <Form.Group controlId="formBasicPassword">
                                    <Form.Control required
                                        name='telefono'
                                        type="number"
                                        placeholder="(*)Teléfono"
                                        onChange={(e) => {
                                            setTelefono(e.target.value);
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group controlId="formBasicPassword">
                                    <Form.Control required
                                        name='email'
                                        type="email"
                                        placeholder="(*)E-Mail"
                                        onChange={(e) => {
                                            setEmail(e.target.value);
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group controlId="formBasicPassword">
                                    <Form.Control required
                                        name='consulta'
                                        as="textarea" rows={3}
                                        type="text"
                                        placeholder="(*)Consulta"
                                        onChange={(e) => {
                                            setConsulta(e.target.value);
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicCheckbox">
    <Form.Check type="checkbox" label="Cuenta con planos de arquitectura?" />
  </Form.Group>

                                <Form.Text className="text-muted text-right py-3 mb-3">
                                        No compartiremos tu información con nadie.
                                    </Form.Text>
                                <div className="text-center">
                                    <Button type="submit" className="btn-rn py-3" variant="secondary">
                                        Enviar
                                    </Button>
                                </div>
                            </Form>

                        </div>
                    </Col>

                    <Col className="text-center d-none d-md-block">
                        <Image
                            src={`${process.env.PUBLIC_URL}img/imgContacto1.JPG`}
                            width="80%"
                            alt="maipu 398"
                            rounded
                            loading='lazy'        
                        />
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default Contacto;