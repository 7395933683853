import React from 'react';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Image from 'react-bootstrap/Image';
import "../../nosotros.css";
import { CSSTransition } from 'react-transition-group';
import { queryAllByDisplayValue } from '@testing-library/dom';
import ListGroup from 'react-bootstrap/ListGroup';

var background1 = {
    backgroundImage: `${process.env.PUBLIC_URL}img/servicios1.png`
}

const Nosotros = () => {
    return (
        <div>
            <div className="container-fluid bg-light text-dark py-3">
                <Row className="d-flex justify-content-center">
                    <Col xs={12} lg={1} ></Col>
                    <Col xs={10} lg={5} className='rounded mx-auto my-auto d-block'>
                        <div className="justify-content-center d-flex">
                            <Image
                            src={`${process.env.PUBLIC_URL}img/nosotros.jpeg`}
                            width="90%"
                            alt="Agustin Paz y Cristian Leon"
                            roundedCircle
                        />
                        </div>
                    </Col>
                    <Col xs={12} lg={5} className="p-5 text-justify mx-auto my-auto d-block">
                        <h4>¡Hola!</h4>
                        <br />
                        <h4>Somos <b>Agustín Paz</b> y <b>Cristian León</b>, ingenieros civiles.
                            <br /><br />
                            Formamos un equipo especializado en la concepción, el desarrollo y la ejecución de
                            proyectos de ingeniería y construcción.
                            <br /><br />
                            Nos dedicamos a brindar soluciones integrales en la construcción de obras civiles,
                            ya sean públicas o privadas.
                            <br /><br />
                            Eficiencia, seguridad, compromiso e innovación son nuestros valores más importantes
                            a la hora de encarar cualquier
                            proyecto, haciendo hincapié en cada detalle durante todo el proceso de trabajo.
                        </h4>
                        <br />
                        <h4>¿Tenés un proyecto en mente? ¡No dudes en llamarnos!</h4>
                    </Col>
                    <Col xs={12} lg={1}></Col>
                </Row>
                <div className="text-dark py-3 justify-content-center m-5 d-flex">
                    <h3>¿Qué servicios brindamos?</h3>
                </div>
                <Row className="d-flex justify-content-center my-5 px-5">
                    <Col xs={12} lg={4} className="d-flex justify-content-center p-0 m-0">
                        <div className="background1 p-0 m-0"></div>
                        <div className="serviciosText">
                            <h2>Obras Civiles Integrales, Públicas y Privadas</h2>
                        </div>
                    </Col>
                    <Col xs={12} lg={4} className="d-flex justify-content-center p-0 m-0">
                        <div className="background2 p-0 m-0"></div>
                        <div className="serviciosText">
                        <h2>Rehabilitación Estructural</h2>
                        </div>
                    </Col>
                    <Col xs={12} lg={4} className="d-flex justify-content-center p-0 m-0">
                        <div className="background3 p-0 m-0"></div>
                        <div className="serviciosText">
                        <h2>Verificaciones Técnicas</h2>
                        </div>
                    </Col>
                    <Col xs={12} lg={4} className="d-flex justify-content-center p-0 m-0">
                        <div className="background4 p-0 m-0"></div>
                        <div className="serviciosText">
                        <h2>Dirección Técnica</h2>
                        </div>
                    </Col>
                    <Col xs={12} lg={4} className="d-flex justify-content-center p-0 m-0">
                        <div className="background5 p-0 m-0 "></div>
                        <div className="serviciosText">
                        <h2>Análisis y Cálculos Estructurales</h2>
                        </div>
                    </Col>
                    <Col xs={12} lg={4} className="d-flex justify-content-center p-0 m-0">
                        <div className="background6 p-0 m-0"></div>
                        <div className="serviciosText">
                        <h2>Project Management</h2>
                        </div>
                    </Col>
                </Row>

                <Row className='mt-5'>
                    {/*    <Col className="text-center d-none d-md-block">
                        <Image
                            src={`${process.env.PUBLIC_URL}img/banner1.png`}
                            width="100%"
                            alt="Lapcon Ingenieria"

                        />
                    </Col>
                    */}
                </Row>
            </div>
        </div >
    );
};

export default Nosotros;