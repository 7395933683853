import './App.css';
import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Inicio from './components/principal/Inicio';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import Contacto from './components/layout/Contacto';
import Nosotros from './components/layout/Nosotros';
import Obras from './components/layout/Obras';

function App() {
  return (
    <Router>

      <Header></Header>
      
      <Switch>
        <Route exact path='/'>
          <Inicio></Inicio>
        </Route>
        <Route exact path='/nosotros'>
          <Nosotros></Nosotros>
        </Route>
        <Route exact path='/obras'>
          <Obras></Obras>
        </Route>
        <Route exact path='/contacto'>
          <Contacto></Contacto>
        </Route>
      </Switch>

      <Footer></Footer>
    </Router>
  );
}

export default App;
