import React from 'react';
import "../../index.css";

const Inicio = () => {
    return (
        <div className='text-center'>
            <div className='div_imagen_construccion'>
                {/* <h3 className="color-rojo my-4 py-4 text-center">PAGINA EN CONSTRUCCION</h3> */}
                <img
                    src={`${process.env.PUBLIC_URL}img/maipu398_1.jpg`}
                    width="100%"
                    alt="maipu 398"
                    className="imagen_construccion"
                    >
                </img>
                <div className="div_subimagen py-4 text-light">
                    <hr className='bg-light hr_construccion'></hr>
                    <h2 className='text-light my-4 py-4'>SITIO EN CONSTRUCCIÓN</h2>
                    <hr className='bg-light'></hr>
                    
                    <h4 className='py-4'>En breve podrás ver nuestro portfolio y los servicios que brindamos</h4>
                </div>
            </div>

            {/* <Carousel>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={process.env.PUBLIC_URL+"/img/carousel/obra2.jpeg"}
                        alt="First slide"
                    />
                    <Carousel.Caption>
                        <h3 className="carousel-text">Titulo Obra</h3>
                        <p className="carousel-text">Descripcion obra</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={process.env.PUBLIC_URL+"/img/carousel/obra1.jpg"}
                        alt="Third slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={process.env.PUBLIC_URL+"/img/carousel/obra3.jpg"}
                        alt="Third slide"
                    />
                </Carousel.Item>
            </Carousel>
            <Fragment>
                <div className="text-center mt-4 pt-5">
                    <h1 className="color-rojo">NUESTROS SERVICIOS</h1>
                    <hr></hr>
                </div>
                
            </Fragment> */}
        </div>
    );
};

export default Inicio;