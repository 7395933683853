import React from 'react';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { FaFacebook, FaInstagram, FaWhatsapp } from "react-icons/fa";
import { HiOutlineMail, HiOutlineLocationMarker } from "react-icons/hi";

const Footer = () => {
    return (
        <div>
            <div className="container-fluid bg-light text-dark py-3 border">
                <Row className="d-flex justify-content-center">
                    <Col md={3} className='my-3'>
                        <div className='text-center'>
                            <a
                                href="#home"
                            >
                                <div>
                                    <img
                                        src={`${process.env.PUBLIC_URL}img/logopng.png`}
                                        width="150rem"
                                        alt="logo lapcon"></img>
                                </div>
                            </a>
                        </div>
                    </Col>

                    <Col md={3} className="my-3">
                        <p className="text-center">Encontranos en nuestras redes:</p>
                        <Row className="d-flex justify-content-center">
                            <Col md={2} className='text-center'>
                                <a
                                    className="boton-footer"
                                    href="https://www.facebook.com/Lapcon-Ingenier%C3%ADa-y-Construcci%C3%B3n-105108411582252"
                                    target="blank_"
                                >
                                    <FaFacebook />
                                </a>
                            </Col>
                            <Col md={2} className='text-center'>
                                <a
                                    className="boton-footer"
                                    href="https://www.instagram.com/lapconingenieria/?hl=es"
                                    target="blank_"
                                >
                                    <FaInstagram />
                                </a>
                            </Col>
                        </Row>


                    </Col>
                    <Col md={3} className="my-3">
                        <p className="text-center">Contacto:</p>
                        <div className='pt-2 text-center'>
                            <a
                                className="boton-footer-wts mx-2"
                                href="
                            https://wa.link/at0lf2"
                                target="blank_"
                            >
                                <FaWhatsapp /> +3815358520
                            </a>
                        </div>
                        <div className='pt-2 text-center'>
                            <a
                                className="boton-footer-wts mx-2"
                                href="https://wa.link/qhujhv"
                                target="blank_"
                            >
                                <FaWhatsapp /> +3814408250
                            </a>
                        </div >
                        <div className='pt-2 text-center'>
                            <a
                                className="boton-footer-wts mx-2"
                                href="mailto:cristianleon@lapcon.com.ar"

                            >
                                <HiOutlineMail /> cristianleon@lapcon.com.ar
                            </a>
                        </div>
                        <div className='pt-2 text-center'>
                            <a
                                className="boton-footer-wts mx-2"
                                href="mailto:agustinpaz@lapcon.com.ar"

                            >
                                <HiOutlineMail /> agustinpaz@lapcon.com.ar
                            </a>
                        </div>
                    </Col>


                </Row>
            </div>
            <div className="container-fluid bg-light text-dark text-center pt-3 border">
                <p>&copy;Todos los derechos reservados | <HiOutlineLocationMarker className='boton-footer-location' />San Miguel de Tucumán - Argentina</p>
            </div>
        </div>
    );
};

export default Footer;