import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Link, NavLink } from "react-router-dom";

import "../../App.css"


const Header = () => {
    return (
        <Navbar bg="light" className="py-3 border" expand="lg">
            <Navbar.Brand href="#home">
                <img
                src={`${process.env.PUBLIC_URL}img/logopng1L.png`}
                width="50rem"
                alt="logo lapcon"
                ></img>
                <img
                src={`${process.env.PUBLIC_URL}img/logopng1LAPCON.png`}
                width="150rem"
                alt="lapcon"
                className="mx-2"
                ></img>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
                <NavLink className="navbar-text mx-2" exact={true} to="/">Inicio</NavLink>
                <NavLink className="navbar-text mx-2" exact={true} to="/nosotros">Quienes somos</NavLink>
                <NavLink className="navbar-text mx-2" exact={true} to="/obras">Nuestras obras</NavLink>
                <NavLink className="navbar-text mx-2" exact={true} to="/contacto">Contacto</NavLink>
            </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default Header;